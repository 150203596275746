import firebase from "firebase/app";
import "firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyBU1VChYsJJbEqn_WkwrmRKZYShsEAYvd8",
  authDomain: "pwaone-40a0c.firebaseapp.com",
  databaseURL: "https://pwaone-40a0c.firebaseio.com",
  projectId: "pwaone-40a0c",
  storageBucket: "pwaone-40a0c.appspot.com",
  messagingSenderId: "619104352035",
  appId: "1:619104352035:web:7bfeaf707fc2ee440c7e96",
};

firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();
//
// if (firebase.messaging.isSupported()) {
//   console.log("support");
// }

export const fromBackGround = () => {
  return messaging
    .onMessage(function (payload) {
      console.log("from firebase Received background message ", payload);

      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
      };

      // self.registration.showNotification(
      //   notificationTitle,
      //   notificationOptions
      // );
    })
    
};

if ("Notification" in window) {
  console.log("supported");
} else {
  console.log(" not supported");
}

export const getToken = (setTokenFound) => {
  return messaging
    .getToken({
      vapidKey:
        "BM75e1HyqjbHJaqpAl-ZKvVS8FnJ8MXE4ZpYiCxl9nHlyJZm7AQayKyi7ZynS4rT4sVJHuufhS36esXwIRUA0H4",
    })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        setTokenFound(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound("");
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
