import axios from "axios";

axios.defaults.baseURL = "https://evroxhealthcare.com:3007";
// axios.defaults.baseURL= 'localhost:3005';
axios.defaults.timeout = 2500;
// export const API_URL = 'https://evone.app:3005'; // dev
export const API_URL = 'https://evone.app:3007'; // production
// export const API_URL = "http://localhost:3007";
// export const API_URL = "http://192.168.0.104:3007";

//...this method use for get Api ..........
export function getApi(url, header) {
  console.log("calling header..... " + header.information);
  //console.log("calling get api..... " + API_URL + url);
  return axios.get(`/${url}`, {
    headers: {
      // Accept: header.Accept,
      //'Content-type': header.contenttype,
      Information: header.information,
      Authorization: header.Authorization,
    },
  });
}
//........End.....................

export const checkMimeType = (event, setMsg) => {
  console.log("helper", event);
  //getting file object
  let files = event.target.files;
  //define message container
  let err = "";
  // list allow mime type
  const types = ["image/png", "image/jpg", "image/jpeg", "image/gif"];
  // loop access array
  for (var x = 0; x < files.length; x++) {
    // compare file type find doesn't matach
    if (types.every((type) => files[x].type !== type)) {
      // create error message and assign to container
      // err += files[x].type + " is not a supported format\n use png,jpg,jpeg and gif.";
      err += "File is not a supported format\n use png, jpg, jpeg, gif";
    }
  }

  if (err !== "") {
    // if message not same old that mean has error
    event.target.value = null; // discard selected file
    console.log(err);
    setMsg({ fileErrorMsg: err, message: "Register" });
    return setMsg;
  }
  setMsg({ fileErrorMsg: "", message: "Register" });
  return true;
};

///......for Post method Api.....
export function postAipCall(url, payload, header) {
  // console.log("postAipCall..... " + API_URL +  '/' + url);
  // console.log("logout payload " + JSON.stringify(payload));
  // console.log("logout header " + JSON.stringify(header));

  return axios.post(`${API_URL}/${url}`, payload, {
    // headers: {
    //     "Accept": header.Accept,
    //     'Content-Type': header.contenttype,
    //     'Authorization': header.Authorization,
    //     'Information': header.information,
    // }
  });
}

export function editUser(url, payload, header) {
  console.log("calling edit api..... " + API_URL + url);
  console.log("payload::: " + JSON.stringify());
  return axios.put(`${API_URL}/${url}`, payload, {
    headers: {
      information: header.information,
      "Content-Type": header.contenttype,
      Authorization: header.Authorization,
    },
  });
}
