import React, {
  lazy,
  Suspense,
  useState,
  useMemo,
  useEffect,
  useRef,
} from "react";
import {
  BrowserRouter,
  Route,
  NavLink,
  Redirect,
  Switch,
  useParams,
  HashRouter,
  useHistory,
} from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';

// import $ from "jquery";

import Loader from "./components/Loader";
import { UserContext } from "./components/UserContext";
// import NoMatch from "./components/NoMatch";
// import ScrollTopArrow from "./components/Helper/ScrollTopArrow";
import ScrollToTop from "./components/ScrollToTop";
// import SplashScreen from "./components/SplashScreen";
import { getToken, onMessageListener, fromBackGround } from "./firebase";
import { Toast } from "react-bootstrap";

import { createHashHistory } from "history";

import { API_URL } from "./utils/ApiRequest";

// import socketClient from "socket.io-client";
// import openSocket from "socket.io-client";
import { io } from "socket.io-client";

// import AddToHomeScreen from "@ideasio/add-to-homescreen-react";

import ReactGA from "react-ga";
ReactGA.initialize("G-XW1665F275"); // UA-195968152 // G-V57KSF9KJH
ReactGA.pageview(window.location.pathname + window.location.search);

// const Header = lazy(() => import("./components/Header/Header"));
// const Footer = lazy(() => import("./components/Layout/Footer"));
const SplashScreen = lazy(() => import("./components/SplashScreen"));
const Home = lazy(() => import("./components/Home"));
const DoctorHome = lazy(() => import("./components/DoctorHome"));
const Register = lazy(() => import("./components/Profile/Register"));
const DoctorRegistration = lazy(() =>
  import("./components/Doctor/DoctorRegistration")
);
const Appointment = lazy(() => import("./components/Appointment/Appointment"));
const DoctorAppointment = lazy(() =>
  import("./components/Appointment/DoctorAppointment")
);

const CoronaDoctorApp = lazy(() =>
  import("./components/Appointment/CoronaDoctorApp")
);

const AboutUs = lazy(() => import("./components/Pages/AboutUs"));

const Login = lazy(() => import("./components/Profile/Login"));
const CoronaLogin = lazy(() => import("./components/Profile/CoronaLogin"));
const Contact = lazy(() => import("./components/Pages/Contact"));
const Services = lazy(() => import("./components/Services"));
const Dashboard = lazy(() => import("./components/Dashboard"));

const Profile = lazy(() => import("./components/Profile/Profile"));
const PateintDashboard = lazy(() => import("./components/Profile/Dashboard"));
const MyProfile = lazy(() => import("./components/Profile/MyProfile"));
const MedicalInfo = lazy(() => import("./components/Profile/MedicalInfo"));
const DoctorDetails = lazy(() => import("./components/Doctor/DoctorDetails"));
const CoronaDocDetails = lazy(() =>
  import("./components/Doctor/CoronaDocDetails")
);
const DoctorLogin = lazy(() => import("./components/Doctor/DoctorLogin"));
const DoctorProfile = lazy(() => import("./components/Doctor/DoctorProfile"));
const DoctorUpdateProfile = lazy(() =>
  import("./components/Doctor/DoctorUpdateProfile")
);
const DoctorBookingApp = lazy(() =>
  import("./components/Doctor/DoctorBookingApp")
);
const DoctorAppointmentDetails = lazy(() =>
  import("./components/Doctor/DoctorAppointmentDetails")
);
const DoctorAppointmentHistory = lazy(() =>
  import("./components/Doctor/DoctorAppointmentHistory")
);
const DoctorTransactionHistory = lazy(() =>
  import("./components/Doctor/DoctorTransactionHistory")
);
const PatientAppList = lazy(() =>
  import("./components/Profile/PatientAppList")
);
const PatientAppDetails = lazy(() =>
  import("./components/Profile/PatientAppDetails")
);
const PatientAppHistory = lazy(() =>
  import("./components/Profile/PatientAppHistory")
);
const PatientTransactionHistory = lazy(() =>
  import("./components/Profile/PatientTransactionHistory")
);
const DoctorPrescriptionHistory = lazy(() =>
  import("./components/Doctor/Appointment/DoctorPrescriptionHistory")
);
const ForgotPassword = lazy(() => import("./components/ForgotPassword"));

const VerifyOtpForFP = lazy(() => import("./components/VerifyOtpForFP"));
const ResetPassword = lazy(() => import("./components/ResetPassword"));
const DoctorDashboard = lazy(() =>
  import("./components/Doctor/DoctorDashboard")
);
const DoctorWallet = lazy(() => import("./components/Doctor/DoctorWallet"));
const DoctorCovidWallet = lazy(() =>
  import("./components/Doctor/DoctorCovidWallet")
);
const DoctorWalletDetails = lazy(() =>
  import("./components/Doctor/DoctorWalletDetails")
);
const DoctorCovidWalletDetails = lazy(() =>
  import("./components/Doctor/DoctorCovidWalletDetails")
);
const Media = lazy(() => import("./components/Media"));
const PatientMedia = lazy(() => import("./components/Profile/Media"));
const PatientPrescriptionHistory = lazy(() =>
  import("./components/Profile/PatientPrescriptionHistory")
);
const PatientCovidPrescriptionHistory = lazy(() =>
  import("./components/Profile/PatientCovidPrescriptionHistory")
);
const DoctorCovidPresList = lazy(() =>
  import("./components/Doctor/DoctorCovidPresList")
);
const MyHealth = lazy(() => import("./components/Profile/MyHealth"));
const Help = lazy(() => import("./components/Pages/Help"));
const TermsOfUse = lazy(() => import("./components/Pages/TermsOfUse"));
const PrivacyPolicy = lazy(() => import("./components/Pages/PrivacyPolicy"));
const ViewPrescription = lazy(() =>
  import("./components/Profile/ViewPrescription")
);
const ViewCovidPrescription = lazy(() =>
  import("./components/Profile/ViewCovidPrescription")
);

const NewPrescription = lazy(() =>
  import("./components/Profile/NewPrescription")
);
const NewCovidPrescription = lazy(() =>
  import("./components/Profile/NewCovidPrescription")
);
const CovidContent = lazy(() => import("./components/Pages/CovidContent"));
const CoronaReg = lazy(() => import("./components/Profile/CoronaReg"));
const CoronaHome = lazy(() => import("./components/Profile/CoronaHome"));
const ForgotSpPassword = lazy(() => import("./components/ForgotSpPassword"));
const ResetSpPassword = lazy(() => import("./components/ResetSpPassword"));
const VerifyOtpForSp = lazy(() => import("./components/VerifyOtpForSp"));
const CovidPatientAppList = lazy(() =>
  import("./components/Profile/CovidPatientAppList")
);
const CovidCnList = lazy(() => import("./components/Doctor/CovidCnList"));
const RefundRequestList = lazy(() =>
  import("./components/Profile/RefundRequestList")
);
const NotFound = lazy(() => import("./components/Pages/NotFound"));

//

// const socket = socketClient(API_URL);
// socket.on("connection", () => {
//   console.log(`I'm connected with the back-end`);
// });

// const socket = openSocket("http://localhost:8000");

function App() {
  // const userLocalData = localStorage.getItem("user");
  const initialLocaUser = () => localStorage.getItem("user") || null;
  const [value, setValue] = useState(initialLocaUser);

  let parseVal = JSON.parse(value);
  console.log("from app page=====", parseVal);

  useEffect(() => {
    window.scrollTo(0, 0);
    const userLocalData = localStorage.getItem("user");
    setValue(userLocalData);
    // unmount
    return () => {
      // console.log("cleanup==");
    };
  }, [value]);

  const providerValues = useMemo(
    () => ({ value, setValue }),
    [value, setValue]
  );

  // push notification
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState("");
  getToken(setTokenFound);

  // const mk = fromBackGround()

  // console.log("notification=====", notification);
  localStorage.setItem("ufcm", isTokenFound);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
        image: payload.notification.image,
        click_action: payload.notification.click_action,
      });
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("../firebase-messaging-sw.js")
      .then(function (registration) {
        console.log(
          "from app.js Registration successful, scope is:",
          registration.scope
        );
      })
      .catch(function (err) {
        console.log("Service worker registration failed, error:", err);
      });
  }

  //
  const history = createHashHistory();
  const clickPush = () => {
    history.push("/cclist");
  };

  return (
    <>
      {/* <div class="preloader-background">
            <div class="preloader-wrapper">
                <div id="preloader"></div>
            </div>
        </div> */}

      {/* <ScrollTopArrow /> */}

      {/* <AddToHomeScreen /> */}

      <Toast
        onClose={() => setShow(false)}
        show={show}
        // delay={10000}
        // autohide
        animation
        // style={{
        //   // position: "absolute",
        //   // top: 20,
        //   // right: 20,
        //   minWidth: 215,
        //   margin: "10px auto",
        // }}
        className="notification notification-ios bg-dark1-dark"
      >
        <Toast.Header style={{ backgroundColor: "#343a40" }}>
          <span className="notification-icon">
            <i className="fa fa-bell"></i>
            <em>Evrox Healthcare</em>
            {/* <i data-dismiss="notification-1" className="fa fa-times-circle"></i> */}
          </span>
          {/* <img
            src={notification.image}
            className="rounded mr-2"
            style={{ width: "40px" }}
            alt=""
          />
          <strong className="mr-auto">{notification.title}</strong>
          <small>just now</small> */}
        </Toast.Header>
        <Toast.Body onClick={clickPush}>
          <h1 className="font-15 color-white mb-n3 font-400">
            {notification.title}
          </h1>
          <div className="pt-2 pb-1 color-white font-12 font-400">
            <p>{notification.body}</p>
          </div>
        </Toast.Body>
      </Toast>

      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <UserContext.Provider value={providerValues}>
            {/* <Header locaData={value ? JSON.parse(value) : ""} /> */}
            {/* <Route exact path="/" component={Home} /> */}
            <ScrollToTop />

            <div className="page-content__">
              <Switch>
                <Route exact path="/" component={SplashScreen} />
                {/* patient */}

                <Route exact path="/covidlogin">
                  <CoronaLogin />
                </Route>

                <Route exact path="/patient_register">
                  <Register />
                </Route>
                <Route exact path="/patient_ph" component={Home} />
                <Route
                  exact
                  path="/forgot_password"
                  component={ForgotPassword}
                />
                <Route exact path="/verify_otp" component={VerifyOtpForFP} />
                <Route exact path="/reset_password" component={ResetPassword} />
                <Route exact path="/patient_login">
                  <Login />
                </Route>
                <Route exact path="/docapp" component={DoctorAppointment} />
                {/* <Route exact path="/covid19" component={CovidContent} /> */}
                <Route exact path="/covidreg" component={CoronaReg} />
                <Route exact path="/covid19" component={CoronaHome} />
                {/* mixed */}
                <Route exact path="/services">
                  <Services />
                </Route>
                <Route exact path="/aboutus">
                  <AboutUs />
                </Route>
                <Route exact path="/contact" component={Contact} />

                <Route exact path="/media" component={Media} />

                <Route exact path="/contactus" component={Help} />
                <Route exact path="/termsofuse" component={TermsOfUse} />
                <Route exact path="/privacy" component={PrivacyPolicy} />
                {/* eof mixed */}
                {/* doctor without login */}
                <Route exact path="/sp_signin" component={DoctorLogin} />
                <Route exact path="/sp_signup" component={DoctorRegistration} />
                <Route exact path="/specialist_sh" component={DoctorHome} />

                <Route exact path="/refunds" component={RefundRequestList} />
                <Route
                  exact
                  path="/forgot_sp_password"
                  component={ForgotSpPassword}
                />
                <Route
                  exact
                  path="/reset_sp_password"
                  component={ResetSpPassword}
                />
                <Route exact path="/verify_sp_otp" component={VerifyOtpForSp} />
                {/* eof doctor without login */}

                {parseVal ? (
                  parseVal.userType === "patient" ? (
                    <>
                      <Route
                        exact
                        path="/covidconsult"
                        component={CoronaDoctorApp}
                      />
                      <Route
                        exact
                        path="/cclist"
                        component={CovidPatientAppList}
                      />
                      <Route
                        exact
                        path="/ccbook/:id"
                        component={CoronaDocDetails}
                      />
                      <Route exact path="/newapp" component={Appointment} />
                      {/* <Route
                        exact
                        path="/refunds"
                        component={RefundRequestList}
                      /> */}
                      <Route
                        exact
                        path="/bookappointment/:id"
                        component={DoctorDetails}
                      />
                      <Route
                        exact
                        path="/profile/applist"
                        component={PatientAppList}
                      />
                      <Route
                        exact
                        path="/profile/patientappdetails/:id"
                        component={PatientAppDetails}
                      />
                      <Route
                        exact
                        path="/profile/apphistory"
                        component={PatientAppHistory}
                      />
                      <Route
                        exact
                        path="/profile/tranhistory"
                        component={PatientTransactionHistory}
                      />
                      <Route
                        exact
                        path="/profile/myhealth"
                        component={MyHealth}
                      />
                      <Route
                        exact
                        path="/profile/dashboard"
                        component={PateintDashboard}
                      />
                      <Route
                        exact
                        path="/profile/manageprofile"
                        component={MyProfile}
                      />
                      <Route
                        exact
                        path="/profile/medicalinfo"
                        component={MedicalInfo}
                      />
                      <Route
                        exact
                        path="/profile/prescriptionhistory"
                        component={PatientPrescriptionHistory}
                      />
                      <Route
                        exact
                        path="/profile/ccpreslist"
                        component={PatientCovidPrescriptionHistory}
                      />
                      <Route
                        exact
                        path="/profile/media"
                        component={PatientMedia}
                      />
                      <Route
                        exact
                        path="/viewprescription"
                        component={ViewPrescription}
                      />

                      <Route
                        exact
                        path="/viewccpres"
                        component={ViewCovidPrescription}
                      />
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  <>
                    <Route
                      exact
                      path="/covidconsult"
                      component={CoronaDoctorApp}
                    />
                    <Route path="*" component={NotFound} />
                  </>
                )}

                {/* eof patient */}

                {/* doctor */}

                <Route exact path="/doctorprofile" component={DoctorProfile} />
                <Route
                  exact
                  path="/specialist/dashboard"
                  component={DoctorDashboard}
                />

                <Route
                  exact
                  path="/specialist/manageprofile"
                  component={DoctorUpdateProfile}
                />
                <Route
                  exact
                  path="/specialist/bookingapp"
                  component={DoctorBookingApp}
                />
                <Route
                  exact
                  path="/specialist/apphistory"
                  component={DoctorAppointmentHistory}
                />
                <Route
                  exact
                  path="/specialist/wallet"
                  component={DoctorWallet}
                />
                <Route
                  exact
                  path="/specialist/covidwallet"
                  component={DoctorCovidWallet}
                />
                <Route
                  exact
                  path="/specialist/transcdetails"
                  component={DoctorWalletDetails}
                />
                <Route
                  exact
                  path="/specialist/covidtnxlist"
                  component={DoctorCovidWalletDetails}
                />
                <Route
                  exact
                  path="/specialist/prescription/history"
                  component={DoctorPrescriptionHistory}
                />
                <Route
                  exact
                  path="/specialist/tranhistory"
                  component={DoctorTransactionHistory}
                />
                <Route
                  exact
                  path="/specialist/docappdetails/:id"
                  component={DoctorAppointmentDetails}
                />
                <Route exact path="/dashboard">
                  <DoctorUpdateProfile />
                  {/* <Dashboard /> */}
                </Route>
                <Route exact path="/profile" component={Profile} />
                {/* <Route exact path="/profile/myprofile" component={Profile} /> */}
                <Route
                  exact
                  path="/specialist/ccpreslist"
                  component={DoctorCovidPresList}
                />
                <Route
                  exact
                  path="/specialist/newprescription"
                  component={NewPrescription}
                />
                <Route
                  exact
                  path="/viewccpres"
                  component={ViewCovidPrescription}
                />
                <Route
                  exact
                  path="/specialist/ccpres"
                  component={NewCovidPrescription}
                />

                <Route
                  exact
                  path="/specialist/covidlist"
                  component={CovidCnList}
                />
                {/* eof doctor */}
                <Route path="*" component={NotFound} />

                {/* <Route>
                      <NoMatch />
                    </Route> */}
              </Switch>
            </div>

            {/* <Footer /> */}
          </UserContext.Provider>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
